import { Modal, Form, Select, Input, Button, Row, Space } from 'antd';
import { MainSubStructure } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { selectData } from './createReplenishmentModalStore';
import type { CreateReplenishmentModalStore } from './createReplenishmentModalStore';

export const CreateReplenishmentModal = observer(({ createReplenishmentModalStore }: { createReplenishmentModalStore: CreateReplenishmentModalStore; }) => {
  const {
    handleClose,
    open,
    formRef,
    handleFinish,
    isSubmit,
    ownerData,
    goodsChooseStore,
    goodsChooseResultStore,
    selectValue,
    inputValue,
    handleInputValueChange,
    handleSelectValueChange,
    handleAdd,
    handleOwnerIdChange,
  } = createReplenishmentModalStore;

  return (
    <Modal
      centered
      destroyOnClose
      maskClosable={false}
      okButtonProps={{ loading: isSubmit }}
      onCancel={handleClose}
      onOk={handleFinish}
      open={open}
      title="创建补货单"
      width={800}
    >
      <Form
        layout="horizontal"
        onFinish={handleFinish}
        ref={formRef}
      >
        <Form.Item
          label="货主"
          name="ownerId"
          rules={[
            {
              required: true,
              message: '请选择货主',
            },
          ]}
        >
          <Select
            onChange={handleOwnerIdChange}
            optionFilterProp="label"
            options={ownerData}
            placeholder="请选择货主"
            showSearch
          />
        </Form.Item>
      </Form>
      <Row
        align="middle"
        justify="space-between"
      >
        <Space size="small">
          <Select
            onChange={handleSelectValueChange}
            options={Object.values(selectData)}
            placeholder="请选择"
            style={{ width: 120 }}
            value={selectValue}
          />
          <Input
            allowClear
            onChange={(event) => handleInputValueChange(event.target.value)}
            onPressEnter={() => goodsChooseStore.gridModel.onQuery()}
            placeholder="请输入"
            value={inputValue}
          />
          <Button
            onClick={() => goodsChooseStore.gridModel.onQuery()}
            type="primary"
          >
            查询
          </Button>
        </Space>
        <Button
          className="ghost-bg-btn"
          onClick={() => handleAdd(goodsChooseStore.gridModel.selectRows)}
        >
          批量添加
        </Button>
      </Row>
      <div style={{ height: 240 }}>
        <MainSubStructure store={goodsChooseStore}/>
      </div>
      <div style={{ height: 240 }}>
        <MainSubStructure store={goodsChooseResultStore}/>
      </div>
    </Modal>
  );
});
