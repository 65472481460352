import type { FormInstance } from 'antd';
import { Modal, message, InputNumber } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { MainSubStructureModel, request, ImgFormatter, NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import { Observer } from 'mobx-react';
import React from 'react';
import { formatNumber } from 'egenie-common';
import type { SafeStockItem } from '../types';

interface Params {
  restockRatio: number;
  thresholdRatio: number;
}

export class ParamsSettingModalStore {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
      {
        type: 'select',
        field: 'ownerId',
        label: '货主',
      },
      {
        type: 'select',
        field: 'status',
        label: '设置状态',
        data: [
          {
            value: '0',
            label: '未设置',
          },
          {
            value: '1',
            label: '已设置',
          },
        ],
      },
    ],
    count: 4,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });

  public formRef = React.createRef<FormInstance>();

  @observable public isLoading = false;

  @observable public open = false;

  @action public handleOpen = () => {
    this.open = true;
    request<BaseData<Params>>({ url: '/api/cloud/wms/rest/replenish/autoRestock/query' })
      .then((info) => {
        this.formRef.current?.setFieldsValue({
          restockRatio: formatNumber(info?.data?.restockRatio) || undefined,
          thresholdRatio: formatNumber(info?.data?.thresholdRatio) || undefined,
        });
      });
  };

  @action public handleClose = () => {
    this.open = false;
    this.mainSubStructureModel.gridModel.clearToOriginal();
  };

  @action public handleFinish = (data: Params) => {
    this.isLoading = true;
    request({
      url: '/api/cloud/wms/rest/replenish/autoRestock/set',
      method: 'POST',
      data,
    })
      .then(() => {
        message.success('保存成功');
        this.handleClose();
      })
      .finally(() => this.isLoading = false);
  };

  @action public handleDelete = (originData: SafeStockItem[]) => {
    const rows: SafeStockItem[] = originData.filter((item) => item.wmsSafeStockConfigId);
    if (rows.length <= 0) {
      const error = '请至少选择一条已设置数据';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      content: '确定删除吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/replenish/safeStock/delete',
          method: 'post',
          data: {
            wmsSafeStockConfigIds: rows.map((item) => item.wmsSafeStockConfigId)
              .join(','),
          },
        });
        message.success('删除成功');
        this.mainSubStructureModel.onRefresh();
      },
    });
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 60,
          frozen: true,
          formatter: ({ row }: { row: SafeStockItem; }) => {
            return row.wmsSafeStockConfigId ? (
              <a onClick={() => this.handleDelete([row])}>
                删除
              </a>
            ) : null;
          },
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 150,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 150,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺寸',
          width: 100,
        },
        {
          key: 'pic',
          name: '图片',
          width: 100,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'status',
          name: '状态',
          width: 60,
        },
        {
          key: 'reserveStockNum',
          name: '备货区库存数量',
          width: 100,
        },
        {
          key: 'safeStockNum',
          name: '安全库存数量',
          width: 120,
          formatter: ({
            rowIdx,
            row,
          }: { row: SafeStockItem; rowIdx: number; }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      min={0}
                      onBlur={() => {
                        if (row.safeStockNum) {
                          request({
                            url: '/api/cloud/wms/rest/replenish/safeStock/add',
                            method: 'post',
                            data: {
                              number: row.safeStockNum,
                              ownerId: row.ownerId,
                              skuId: row.skuId,
                            },
                          })
                            .then(() => {
                              message.success('设置成功');
                            });
                        }
                      }}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].safeStockNum = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].safeStockNum}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      primaryKeyField: 'skuId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showSelectedTotal: true,
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<SafeStockItem>>({
          url: '/api/cloud/wms/rest/replenish/safeStock/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
    buttons: [
      {
        text: '全部移除',
        handleClick: () => this.handleDelete(this.mainSubStructureModel.gridModel.selectRows),
      },
    ],
  });
}
