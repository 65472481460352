import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { SubItem } from './types';

export function detailGrid() {
  return {
    tab: {
      name: '商品明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'restockNum',
          name: '补货数量',
          width: 100,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      primaryKeyField: 'wmsRestockOrderDetailId',
      showCheckBox: false,
      showSelectedTotal: false,
      sortByLocal: false,
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<SubItem[]>>({
          url: '/api/cloud/wms/rest/replenish/detail/page',
          method: 'POST',
          data: {
            wmsRestockOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
        });
      },
    },
  };
}
