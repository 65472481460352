import { Programme, MainSubStructureModel, request, TimeStampFormatter, Permission } from 'egenie-utils';
import React from 'react';
import { getOwner, logSubGrid } from '../../utils';
import { CreateReplenishmentModalStore } from './createReplenishmentModal/createReplenishmentModalStore';
import { detailGrid } from './detailGrid';
import { ParamsSettingModalStore } from './paramsSettingModal/paramsSettingModalStore';
import type { MainItem } from './types';
import { Button, message, Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import type { DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';

export class Store {
  constructor() {
    getOwner()
      .then((data) => {
        this.programme.filterItems.addDict({ ownerId: data });
        this.paramsSettingModalStore.normalProgramme.filterItems.addDict({ ownerId: data });
        this.createReplenishmentModalStore.ownerData = data;
      });

    request<DictData>({ url: '/api/cloud/wms/rest/replenish/order/type' })
      .then((data) => this.programme.filterItems.addDict({ orderTypeStr: objToDict(data) }));

    request<DictData>({ url: '/api/cloud/wms/rest/replenish/order/status' })
      .then((data) => this.programme.filterItems.addDict({ statusStr: objToDict(data) }));
  }

  public paramsSettingModalStore: ParamsSettingModalStore = new ParamsSettingModalStore();

  public createReplenishmentModalStore: CreateReplenishmentModalStore = new CreateReplenishmentModalStore(this);

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '创建',
        handleClick: () => this.createReplenishmentModalStore.handleOpen(),
        permissionId: '45',
      },
      {
        text: '删除',
        handleClick: () => this.handleDelete(this.mainSubStructureModel.gridModel.selectRows),
        permissionId: '6021',
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 80,
          formatter: ({ row }) => (
            <Permission permissionId="6021">
              <Button
                onClick={() => this.handleDelete([row])}
                size="small"
                type="link"
              >
                删除
              </Button>
            </Permission>
          ),
        },
        {
          key: 'orderStateStr',
          name: '单据状态',
          width: 80,
        },
        {
          key: 'wmsRestockOrderNo',
          name: '单据编号',
          width: 150,
        },
        {
          key: 'createUser',
          name: '创建人',
          width: 150,
        },
        {
          key: 'restockNum',
          name: '补货数量',
          width: 150,
        },
        {
          key: 'ownerStr',
          name: '货主',
          width: 150,
        },
        {
          key: 'num',
          name: '数量',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTimeStr}/>,
        },
        {
          key: 'restockTimeStr',
          name: '补货时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.restockTimeStr}/>,
        },
        {
          key: 'orderTypeStr',
          name: '单据类型',
          width: 80,
        },
        {
          key: 'wmsPickingOrderNo',
          name: '波次号',
          width: 150,
        },
        {
          key: 'restockUser',
          name: '补货人',
          width: 150,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      primaryKeyField: 'wmsRestockOrderId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
    },
    btnExtraRight: (
      <Button
        onClick={() => this.paramsSettingModalStore.handleOpen()}
        type="link"
      >
        参数设置
      </Button>
    ),
    pageId: '7317',
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/replenish/order/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        detailGrid(),
        logSubGrid(280000),
      ],
    },
  });

  public programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: [
      {
        type: 'date',
        field: 'date',
        label: '日期类型',
        startParamsField: 'startTime',
        endParamsField: 'endTime',
        data: [
          {
            value: '1',
            label: '创建时间',
          },
          {
            value: '5',
            label: '补货时间',
          },
        ],
      },
      {
        type: 'select',
        label: '单据状态',
        field: 'statusStr',
        mode: 'multiple',
      },
      {
        type: 'input',
        label: '单据编号',
        field: 'restockOrderNo',
      },
      {
        type: 'input',
        label: 'SKU编码',
        field: 'skuNo',
      },
      {
        type: 'select',
        label: '货主',
        field: 'ownerId',
        mode: 'multiple',
      },
      {
        type: 'select',
        label: '单据类型',
        field: 'orderTypeStr',
      },
      {
        type: 'input',
        label: '波次号',
        field: 'wmsPickingOrderNo',
      },
    ],
    moduleName: 'egenieCloudWmsReplenishmentManage',
  });

  private handleDelete = (rows: MainItem[]) => {
    if (rows.length === 0) {
      const error = '请选择数据';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    if (!rows.every((item) => item.orderState === 0)) {
      const error = '仅未开始的补货单可以删除';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    const wmsRestockOrderIds = rows.map((item) => item.wmsRestockOrderId)
      .join(',');

    Modal.confirm({
      content: '确认删除吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/replenish/delete',
          method: 'post',
          data: { wmsRestockOrderIds },
        });
        this.mainSubStructureModel.onRefresh();
      },
    });
  };
}
