import type { FormInstance } from 'antd';
import { message, Button, InputNumber } from 'antd';
import type { PaginationData } from 'egenie-common';
import { formatNumber, request } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel } from 'egenie-utils';
import { action, observable } from 'mobx';
import { Observer } from 'mobx-react';
import React from 'react';
import type { Store } from '../store';

export interface GoodsItem {
  skuId?: string;
  color?: string;
  size?: string;
  pic?: string;
  reserveAreaNum?: number;
  restockNum?: number;
}

export const selectData = {
  skuNo: {
    value: 's#sku_no',
    label: 'sku编码',
  },
  productNo: {
    value: 'p#procuct_no',
    label: '商品编码',
  },
};

export class CreateReplenishmentModalStore {
  constructor(private parent: Store) {
  }

  @observable public ownerData: ValueAndLabelData = [];

  @action public handleOwnerIdChange = () => {
    this.goodsChooseStore.gridModel.clearToOriginal();
    this.goodsChooseResultStore.gridModel.clearToOriginal();
  };

  public formRef = React.createRef<FormInstance>();

  @observable public open = false;

  @action public handleOpen = () => {
    this.open = true;
  };

  @action public handleClose = () => {
    this.selectValue = selectData.skuNo.value;
    this.inputValue = '';
    this.goodsChooseStore.gridModel.clearToOriginal();
    this.goodsChooseResultStore.gridModel.clearToOriginal();
    this.open = false;
  };

  @observable public isSubmit = false;

  public handleFinish = async() => {
    const values = await this.formRef.current.validateFields();
    const detail = this.goodsChooseResultStore.gridModel.rows.map((item) => ({
      skuId: item.skuId,
      restockNum: item.restockNum,
    }));
    if (detail.length <= 0) {
      const error = '请选择数据';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    this.isSubmit = true;
    try {
      await request({
        url: '/api/cloud/wms/rest/replenish/create',
        method: 'post',
        data: {
          ...values,
          detail,
        },
      });
      message.success('创建成功');
      this.handleClose();
      this.parent.mainSubStructureModel.onQuery();
    } finally {
      this.isSubmit = false;
    }
  };

  public goodsChooseStore: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'onlyCode',
          name: '操作',
          formatter: ({ row }) => {
            return (
              <Button
                onClick={() => this.handleAdd([row])}
                type="link"
              >
                添加
              </Button>
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'color',
          name: '颜色',
        },
        {
          key: 'size',
          name: '尺码',
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      primaryKeyField: 'skuId',
      sortByLocal: false,
      showRefresh: false,
    },
    api: {
      onQuery: async(params) => {
        const values = await this.formRef.current.validateFields();
        const {
          filterParams = {},
          ...rest
        } = params;
        if (this.selectValue && this.inputValue) {
          return request<PaginationData<GoodsItem>>({
            url: '/api/cloud/wms/rest/replenish/search/sku',
            method: 'POST',
            data: {
              ...filterParams,
              ...rest,
              key: this.selectValue,
              value: this.inputValue,
              ...values,
            },
          });
        } else {
          const error = '请输入查询条件';
          message.error({
            key: error,
            content: error,
          });
          return Promise.reject();
        }
      },
    },
    hiddenSubTable: true,
  });

  public goodsChooseResultStore: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'onlyCode',
          name: '操作',
          formatter: ({ row }) => {
            return (
              <Button
                onClick={() => this.handleDelete(row)}
                type="link"
              >
                删除
              </Button>
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'color',
          name: '颜色',
        },
        {
          key: 'size',
          name: '尺码',
        },
        {
          key: 'reserveAreaNum',
          name: '备货区库存数量',
          width: 120,
        },
        {
          key: 'restockNum',
          name: '数量',
          formatter: ({
            rowIdx,
            row,
          }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={row.reserveAreaNum}
                      min={0}
                      onChange={(value) => this.goodsChooseResultStore.gridModel.rows[rowIdx].restockNum = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.goodsChooseResultStore.gridModel.rows[rowIdx].restockNum}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      primaryKeyField: 'skuId',
      sortByLocal: false,
      showPager: false,
      showCheckBox: false,
    },
    api: { onQuery: (params) => Promise.reject() },
    hiddenSubTable: true,
  });

  @observable public selectValue = selectData.skuNo.value;

  @action public handleSelectValueChange = (selectValue: string) => {
    this.selectValue = selectValue;
  };

  @observable public inputValue = '';

  @action public handleInputValueChange = (inputValue: string) => {
    this.inputValue = inputValue;
  };

  @action
  public handleAdd = (rows: GoodsItem[]) => {
    rows.forEach((item) => {
      if (!this.goodsChooseResultStore.gridModel.rows.find((val) => val.skuId === item.skuId)) {
        this.goodsChooseResultStore.gridModel.rows.push({
          ...item,
          reserveAreaNum: item.reserveAreaNum || 0,
          restockNum: item.restockNum || item.reserveAreaNum || 0,
        });
      }
    });
  };

  @action
  public handleDelete = (row: GoodsItem) => {
    this.goodsChooseResultStore.gridModel.rows = this.goodsChooseResultStore.gridModel.rows.filter((item) => item.skuId !== row.skuId);
  };
}
