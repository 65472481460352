import { ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { CreateReplenishmentModal } from './createReplenishmentModal/createReplenishmentModal';
import { ParamsSettingModal } from './paramsSettingModal/paramsSettingModal';
import { Store } from './store';

export default function() {
  const store = React.useMemo(() => new Store(), []);
  return (
    <>
      <ProgrammeComponent store={store.programme}/>
      <CreateReplenishmentModal createReplenishmentModalStore={store.createReplenishmentModalStore}/>
      <ParamsSettingModal paramsSettingModalStore={store.paramsSettingModalStore}/>
    </>
  );
}
