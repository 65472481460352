import { Form, Modal, InputNumber, Row, Card } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import type { ParamsSettingModalStore } from './paramsSettingModalStore';
import { egeniePcTheme } from 'egenie-common';

export const ParamsSettingModal = observer(({
  paramsSettingModalStore: {
    formRef,
    open,
    handleClose,
    isLoading,
    handleFinish,
    mainSubStructureModel,
    normalProgramme,
  },
}: { paramsSettingModalStore: ParamsSettingModalStore; }) => {
  return (
    <Modal
      centered
      destroyOnClose
      maskClosable={false}
      okButtonProps={{ loading: isLoading }}
      onCancel={handleClose}
      onOk={() => formRef?.current?.submit()}
      open={open}
      title="参数设置"
      width={1200}
    >
      <Form
        layout="horizontal"
        onFinish={handleFinish}
        ref={formRef}
      >
        <Row align="middle">
          当库存低于安全库存&nbsp;
          <Form.Item
            name="thresholdRatio"
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              max={999999}
              min={0}
              placeholder="请输入"
              style={{ width: 150 }}
            />
          </Form.Item>
          &nbsp;%,自动执行补货,补至安全库存的&nbsp;
          <Form.Item
            name="restockRatio"
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              max={999999}
              min={0}
              placeholder="请输入"
              style={{ width: 150 }}
            />
          </Form.Item>
          &nbsp;%
        </Row>
      </Form>
      <Card
        size="small"
        style={{ marginTop: egeniePcTheme.spacing['egenie-spacing-xss'] }}
      >
        <NormalProgrammeComponent store={normalProgramme}/>
      </Card>
      <div style={{ height: 500 }}>
        <MainSubStructure store={mainSubStructureModel}/>
      </div>
    </Modal>
  );
});
